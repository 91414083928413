import { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload';
import { models } from 'powerbi-client'

import Swal from "sweetalert2"

import { FiMoreHorizontal, FiMaximize2, FiX } from "react-icons/fi"

import { Context } from 'contexts/context'

import NewTopbar from 'components/NewTopbar'

import { FloatingButton, Item } from 'components/FloatingButton'
import Modal from 'components/Modal'
import CardBody from 'components/CardBody'
import Content from 'components/Content'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import BtnBox from 'components/Button/BtnBox'
import BtnOrange from 'components/Button/BtnOrange'
import Dropdown from 'components/Dropdown'
import BtnWhite from 'components/Button/BtnWhite'

import { handleReport } from 'services/api/reports'

import { handleCreateUserActionReport, handleUpdateUserActionReport } from 'services/api/usersActionsReports'
import { handlePowerBiExportReport, handlePowerBiReportAad, handlePowerBiReportEmbed } from 'services/api/powerbi'

import { getUser } from 'services/auth'
import { handleUserShow } from 'services/api/users'
import { PowerBIEmbed } from 'powerbi-client-react'

import { downloadFile } from "utils/index"

import './style.css'

const Page = () => {
  const [data, setData] = useState({})
  const [userActionReportId, setUserActionReportId] = useState()

  const [isDownloading, setIsDownloading] = useState(false)
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [alertIsOpen, setAlertIsOpen] = useState(true)

  const [isLoading, setIsLoading] = useState(true)

  const [reportMobile, setReportMobile] = useState()
  const [reportDesktop, setReportDesktop] = useState()

  const params = useParams()
  const history = useHistory()

  const { user, report, setReport, isLoaded, setIsLoaded, hasMobile, setHasMobile } = useContext(Context)

  const { group_id, report_id, dataset_id } = params

  const getData = async () => {
    try {
      if (user.roles === 'admin') {
        const reportData = await handlePowerBiReportAad({ report_id })

        setData({
          reportId: reportData.reportId,
          embedUrl: reportData.embedUrl,
          token: reportData.accessToken,
          type: models.TokenType.Aad
        })

        return reportData.accessToken;
      }

      const roles = await handleReport(report_id, user.id)

      if (roles.roles) {
        const reportData = await handlePowerBiReportEmbed({
          group_id,
          report_id,
          dataset: dataset_id,
          username: user.email,
          roles: roles.roles
        })

        handleUserAction(roles.report_card_id)

        setData({
          reportId: reportData.reportId,
          embedUrl: reportData.embedUrl,
          token: reportData.embedToken,
          page_navigation: roles.page_navigation,
          enable_filters: roles.enable_filters,
          type: models.TokenType.Embed,
          expiration: reportData.embedToken.expiration
        })

        return reportData.embedToken;
      }

      const reportData = await handlePowerBiReportAad({ report_id })

      handleUserAction(roles.report_card_id)

      setData({
        reportId: reportData.reportId,
        embedUrl: reportData.embedUrl,
        token: reportData.accessToken,
        page_navigation: roles.page_navigation,
        enable_filters: roles.enable_filters,
        type: models.TokenType.Aad
      })

      return reportData.embedToken;
    } catch (error) {
      Swal.fire(
        'Erro',
        'Relatório Indisponível!',
        'error'
      )

      history.goBack()
    }
  }

  useEffect(() => {
    if (user) getData()
  }, [user])

  const handleUserAction = async (report_card_id) => {
    const userActionReportResponse =
      await handleCreateUserActionReport({ report_id: report_card_id })

    setUserActionReportId(userActionReportResponse)
  }

  useBeforeunload(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }
  })

  const unblock = history.block(() => {
    if (user.roles !== 'admin') {
      handleUpdateUserActionReport(userActionReportId)
    }

    setReport(null)
    setIsLoaded(false)
    setHasMobile(false)
    setAlertIsOpen(true)
    setIsLoading(true)
    unblock()
  })

  window.screen.orientation.onchange = event => {
    if (event.target.type === "landscape-primary") {
      setAlertIsOpen(false)

      return
    }
  }

  const handleExport = async ({ format, extension }) => {
    setIsDownloading(true)

    try {
      const data = await handlePowerBiExportReport({
        format,
        report_id
      })

      downloadFile(data, `Relatório.${extension}`)

      Swal.fire(
        'Sucesso',
        'Exportação efetuada com sucesso!',
        'success'
      )
    } catch (error) {
      Swal.fire(
        'Erro',
        'Houve um problema ao efetuar a exportação!',
        'error'
      )
    } finally {
      setIsDownloading(false)
    }
  }

  const embedConfig = {
    type: 'report',
    id: data.reportId,
    embedUrl: data.embedUrl,
    eventHooks: (data.type === models.TokenType.Aad) && { accessTokenProvider: async () => await getData() },
    accessToken: data.token,
    tokenType: data.type || models.TokenType.Aad,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage
      },
      panes: {
        filters: {
          visible: data.enable_filters ? data.enable_filters : false
        },
        pageNavigation: {
          visible: data.page_navigation ? data.page_navigation : false
        }
      }
    }
  }

  useEffect(() => {
    if (isLoaded) {
      if (window.innerWidth <= 600) {
        (async () => {
          try {
            const pages = await reportMobile.getPages()
            const mobileLayout = await pages[0].hasLayout(models.LayoutType.MobilePortrait)

            if (mobileLayout) {
              setHasMobile(true)
            }
          } catch (error) { } finally {
            setIsLoading(false)
          }
        })()
      } else {
        setIsLoading(false)
      }
    }
  }, [isLoaded])

  useEffect(() => {
    if (hasMobile) {
      setReport(reportMobile)
    } else {
      setReport(reportDesktop)
    }
  }, [hasMobile, isLoaded])

  return (
    <div style={{
      marginTop: user.roles === "admin" ? "-10px" : "0px"
    }} id='ReportPageContainer'>
      <div id='reportDesign'>
        <div style={{ visibility: isLoading || hasMobile ? "hidden" : "visible", position: isLoading || hasMobile ? "absolute" : "relative" }}>
          <PowerBIEmbed
            eventHandlers={
              new Map([
                ['loaded', () => setIsLoaded(true)],
                ['rendered', () => setIsLoaded(true)],
                ['error', event => console.log(event.detail)]
              ])
            }
            getEmbeddedComponent={(embedObject) => setReportDesktop(embedObject)}
            embedConfig={embedConfig}
            cssClassName={"report"}
          />
        </div>

        {window.innerWidth <= 600 &&
          <div style={{ visibility: !hasMobile ? "hidden" : "visible", position: !hasMobile ? "absolute" : "relative" }}>
            <PowerBIEmbed
              eventHandlers={
                new Map([
                  ['loaded', () => console.log("")],
                  ['rendered', () => setIsLoaded(true)],
                  ['error', event => console.log(event.detail)]
                ])
              }
              getEmbeddedComponent={(embedObject) => setReportMobile(embedObject)}
              embedConfig={{ ...embedConfig, settings: { ...embedConfig.settings, layoutType: models.LayoutType.MobilePortrait } }}
              cssClassName={"reportMobile"}
            />
          </div>}

      </div>

      {(window.innerWidth <= 600 && alertIsOpen && !(isLoading || hasMobile)) && <div id='alert'>
        <img height={30} src='/system.png' alt='alerta' />
        <span>Para obter uma visão ampliada deste relatório, gire seu telefone para a orientação paisagem</span>
        <BtnOrange
          onClick={() => setAlertIsOpen(!alertIsOpen)}
          isLoading={false}
          style={{
            width: "23px",
            height: "23px",
            padding: 0
          }}
        >
          <FiX size={30} color={"#fff"} />
        </BtnOrange>
      </div>}

      {isLoading && (
        <div id='overlay'>
          <img width={80} height={80} id="spinner" alt="Alternate Text" src={"/editor-2.1s-209px.gif"} />
        </div>
      )}
    </div>
  )
}

export default Page